import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Wrapper from '../components/Wrapper/Wrapper'
import Hero from '../components/Hero/Hero'
import SEO from '../components/SEO/SEO'

class Presentation extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <SEO />
        <Hero title={siteTitle} />

        <Wrapper>
          <h2>Qui sommes-nous?</h2>
          <p>
            Compagnon Kératocône est une association à dimension internationale et à but non lucratif. Elle est régie par les principes généraux du droit applicable aux contrats et obligations, par la loi du 01 juillet 1901 et le décret du 16 août 1901, ainsi que par ses statuts.<br/>
            A la base de la création de l’association : une patiente qui s’est retrouvée totalement démunie après le diagnostic de sa maladie, qui a dû se battre avec ses proches pour vivre avec et ensemble, ils ont décidé de contribuer à améliorer la vie des personnes déficientes en situation de  handicap, quel qu’il soit !<br/>
          </p>
        </Wrapper>
        <Wrapper>
          <h2>Notre mission</h2>
          <p>
            Cette association a pour but d’informer, de sensibiliser les populations sur le Kératocône d’une part et d’autre partir accompagner les personnes en situation de handicap dans leur prise en charge et leur résilience.<br/>
            Son objectif est plus précisément de :
            <ul>
              <li>Mieux faire connaître le kératocône qui est une maladie rare et évolutive de l’œil.</li>
              <li>Suivre et accompagner les patients ainsi que leurs proches.</li>
              <li>Sensibiliser sur les maladies rares des yeux et accompagner toute personne en situation de handicap.</li>
            </ul>
          </p>
        </Wrapper>
      </Layout>
    )
  }
}

export default Presentation

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { ne: "page" } } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            language
            slug
          }
        }
      }
    }
  }
`
